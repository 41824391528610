/* eslint-disable max-len, no-undef */
self.deprecationWorkflow = self.deprecationWorkflow || {};

/*

-- HOW TO WORK WITH THIS FILE --

1. silence deprecations that exist in the app
2. create a linear issue for each deprecation and add as comment next to deprecation
3. change from silence to throw once deprecation has been fixed to prevent new instances from getting introduced into the app again

*/
self.deprecationWorkflow.config = {
  workflow: [
    { handler: 'silence', matchId: 'ember-data:deprecate-early-static' }, // waiting for ember mirage update https://github.com/miragejs/ember-cli-mirage/pull/2444
    { handler: 'silence', matchId: 'ember-data:model-save-promise' }, // this will be solved in a future embed data release
    { handler: 'silence', matchId: 'ember-data:deprecate-promise-proxies' }, // this will be solved in a future embed data release
  ],
};
